angular.module('zwpSite')
    .controller('DisputeModalCtrl', function($scope, $http, $filter, $timeout, $uibModalInstance, locationId) {
        $scope.locationId = locationId;

        $scope.dispute = {};

        /**
         * submits the rating form
         */
        $scope.submitForm = function() {
            var data = angular.extend({}, $scope.dispute);

            $http.post('/locations/' + $scope.locationId + '/dispute', data).
                success(function() {
                    $scope.showSuccess = true;
                    $scope.messageClass = "alert alert-success";

                    $timeout(function() {
                        $uibModalInstance.close(true);
                    }, 5000);
                }).
                error(function() {
                    $scope.showError = true;
                    $scope.messageClass = "alert alert-error";

                    $timeout(function() {
                        $uibModalInstance.close(false);
                    }, 5000);
                });
        };

        $scope.closeModal = function() {
            $uibModalInstance.close();
        };
    });
