angular.module('zwpSite')
    .controller('HomeCtrl', function($scope, $filter, $http) {
        /**
         * fetch regions for autocompletion
         *
         * @param val
         * @returns {*}
         */
        $scope.getRegion = function(val) {
            var countryCode = document.querySelector('select[name=country]').value;

            return $http.get('/api/v1/suggest', {
                params: {
                    region: val,
                    country: countryCode
                }
            }).then(function(response){
                return response.data[0].data.map(function(item){
                    return item.region;
                });
            });
        };
    });
