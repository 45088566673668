angular.module('zwpSite')
    .controller('AgentCtrl', ['$scope', '$http', '$location',
        function($scope, $http, $location) {

            /**
             * confirm
             *
             * @returns {*}
             */
            $scope.confirm = function() {
                return $http.get('/api/v1/searchagent/' + $scope.id + '/confirm/' + $scope.token)
                    .success(function(data) {
                        $scope.confirm.success = true;
                    })
                    .error(function(data) {
                        $scope.confirm.failed  = true;
                    });
            };

            /**
             * delete
             *
             * @returns {*}
             */
            $scope.delete = function() {
                return $http.get('/api/v1/searchagent/' + $scope.id + '/delete/' + $scope.token)
                    .success(function(data) {
                        $scope.delete.success = true;
                    })
                    .error(function(data) {
                        $scope.delete.failed  = true;
                    });
            };

            $scope.init = function(id, token, type) {
                $scope.id = id;
                $scope.token = token;
                $scope.type  = type;

                $scope.confirm.success = false;
                $scope.confirm.failed  = false;

                $scope.delete.success = false;
                $scope.delete.failed  = false;

                if (type == 'confirm') {
                    $scope.confirm();
                }
            };

    }]);
