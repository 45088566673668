angular.module('zwpSite')
    .controller('RemindlistCtrl', ['$scope', '$http', '$cookies', function($scope, $http, $cookies) {
        $scope.working = true;

        $scope.fetchLocations = function() {
            var list = $cookies.getObject('zwp.remindlist');
            $scope.locations = [];

            if(!(list instanceof Array))
            {
                $scope.working = false;
                list = [];
                $cookies.putObject('zwp.remindlist', list);
            }

            angular.forEach(list, function(item) {
                // Fetch location info from our API
                $http.get('/api/v1/location/' + item)
                    .success(function(data)
                    {
                        $scope.locations.push(data[0].data);
                        $scope.working = false;
                    });
            });
        };

        /**
         * removes a location from the reminder list
         */
        $scope.unremindLocation = function(locationId) {
            var list = $cookies.getObject('zwp.remindlist');
            var index = list.indexOf(locationId);

            if (index > -1) {
                list.splice(index, 1);
            }

            $cookies.putObject('zwp.remindlist', list, {path: '/'});
            $scope.fetchLocations();
        };

        $scope.fetchLocations();
    }]);