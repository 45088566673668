angular.module('zwpSite')
    .controller('SaveagentModalCtrl', function($scope, $http, $filter, $timeout, $uibModalInstance, search) {
        $scope.seach = search;

        $scope.form = {};
        $scope.form.title = '';
        $scope.form.email = '';

        /**
         * submits the save agent form
         */
        $scope.submitForm = function() {
            var data = angular.extend({}, $scope.form);
            data.search = $scope.search;

            $http.post('/api/v1/searchagent/add', data).
                success(function() {
                    $scope.showSuccess = true;
                    $scope.messageClass = "alert alert-success";

                }).
                error(function() {
                    $scope.showError = true;
                    $scope.messageClass = "alert alert-error";
                });
        };

        $scope.closeModal = function() {
            $uibModalInstance.close();
        };
    });
