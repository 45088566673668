// Declare app level module which depends on views, and components
angular.module('zwpSite', ['ui.bootstrap', 'checklist-model', 'ngCookies', 'ngEqualheights', 'zwp', 'rzModule'])
    .config(function($interpolateProvider) {
        $interpolateProvider.startSymbol('%%');
        $interpolateProvider.endSymbol('%%');
    }
).filter('dateToMysql', function () {
        return function (input) {
            if (input && input instanceof Date) {
                var day = (input.getDate() < 10) ? ("0" + input.getDate()) : input.getDate();
                var month = parseInt(input.getMonth()) + 1;
                month = (month < 10) ? ("0" + month) : month;

                return input.getFullYear() + "-" + month + "-" + day;
            }
        };
    }
).filter('escapeHtml', function() {
        return function (input) {
            if (typeof input === 'string' || input instanceof String) {
                return input
                    .replace(/&/g, "&amp;")
                    .replace(/</g, "&lt;")
                   .replace(/>/g, "&gt;")
                    .replace(/"/g, "&quot;")
                    .replace(/'/g, "&#039;");
            } else {
                return input;
            }
        };
    }
);