angular.module('zwpSite')
    .controller('LocationCtrl', ['$scope', '$http', '$uibModal', '$location', '$cookies', function($scope, $http, $uibModal, $location, $cookies) {
        $scope.locationId = null;

        $scope.init = function(locationId) {
            $scope.locationId = locationId;
            $scope.location = {};
            $scope.showTelephone = false;
            $scope.ratingToken = false;
            $scope.hasDispute = false;

            // Fetch location info from our API
            $http.get('/api/v1/location/' + $scope.locationId)
                .success(function(data)
                {
                    $scope.location = data[0].data;

                    $scope.initializeMap();
                })
                .error(function(data)
                {
                    alert('Error: ' + data);
                });

            // initialize JS widgets
            $scope.initializeSlider();
            $scope.initializeLightbox();
            $scope.initializeTooltips();

            // log that we view this location
            $scope.logView();

            // show rating form
            if($location.path() == '/rating' && $location.search().token !== undefined)
            {
                $scope.ratingToken = $location.search().token;
                $scope.openRatingModal();
            }
        };

        /**
         * initialize google map
         */
        $scope.initializeMap = function() {
            if(google === undefined)
            {
                return;
            }

            // create coordinates from api
            var coordinates = new google.maps.LatLng($scope.location.lat,$scope.location.lon);

            var mapOptions = {
                zoom: 10,
                center: coordinates,
                minZoom: 9,
                maxZoom: 15,
                scrollwheel: false
            };

            var map = new google.maps.Map(document.getElementById('gmap'), mapOptions);

            new google.maps.Marker({
                position: coordinates,
                map: map,
                icon: '/images/gmapmarker.png'
            });
        };

        /**
         * initialize slider
         */
        $scope.initializeSlider = function() {
            $(document).ready(function() {
                $('.slider').slick({
                    centerMode: true,
                    centerPadding: '600px',
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: true,
                    prevArrow: '<div class="slider__leftoverlay"></div><button type="button" class="slider__prev"></button>',
                    nextArrow: '<div class="slider__rightoverlay"></div><button type="button" class="slider__next"></button>',
                    infinite: true,
                    variableWidth: true,
                    responsive: [
                        {
                            breakpoint: 2000,
                            settings: {
                                centerPadding: '375px'
                            }
                        },
                        {
                            breakpoint: 1550,
                            settings: {
                                centerPadding: '250px'
                            }
                        },
                        {
                            breakpoint: 1200,
                            settings: {
                                centerPadding: '150px'
                            }
                        },
                        {
                            breakpoint: 1000,
                            settings: {
                                centerPadding: '80px'
                            }
                        },
                        {
                            breakpoint: 850,
                            settings: {
                                centerPadding: '50px'
                            }
                        }
                    ]
                });
            });
        };

        /**
         * initialize lightbox plugin
         */
        $scope.initializeLightbox = function() {
            $(document).ready(function() {
                if($('.object__pictures .row').length > 2)
                {
                    $('.object__pictures .row:gt(1)').hide();

                    var button = $('<a href="" class="btn btn-primary">Mehr Bilder</a>');

                    button.click(function() {
                        button.remove();
                        $('.object__pictures .row:gt(1)').show();
                    });

                    button.insertAfter('.object__pictures .row:nth-child(3)');
                }

                $('.lightbox').magnificPopup({type:'image', gallery:{enabled:true}});
            });
        };

        /**
         * initialize popover and tooltip plugin
         */
        $scope.initializeTooltips = function() {
            $(document).ready(function() {
                $('[data-toggle="tooltip"]').tooltip({'delay': { show: 10, hide: 5 }, 'animation': false});
                $('[data-toggle="popover"]').popover({trigger: 'click hover', html: true});

            });
        };

        /**
         * logs a view via our API
         */
        $scope.logView = function() {
            $http.get('/stats/add/' + $scope.locationId + '/2')
                .error(function(data)
                {
                    console.log('Error: ' + data);
                });
        };

        /**
         * opens a modal dialog to add new rating
         */
        $scope.openRatingModal = function() {
            $uibModal.open({
                templateUrl: '/ratings/add',
                controller: 'RatingModalCtrl',
                size: 'lg',
                resolve: {
                    locationId: function () {
                        return $scope.locationId;
                    },
                    ratingToken: function() {
                        return $scope.ratingToken;
                    }
                }
            });
        };

        /**
         * opens a modal dialog to contact the owner
         */
        $scope.openContactModal = function() {
            $uibModal.open({
                templateUrl: '/templates/contactform',
                controller: 'ContactModalCtrl',
                size: 'm',
                resolve: {
                    locationId: function () {
                        return $scope.locationId;
                    }
                }
            });
        };


        /**
         * opens a modal dialog to contact the owner
         */
        $scope.openDisputeModal = function() {
            var modal = $uibModal.open({
                templateUrl: '/templates/disputeform',
                controller: 'DisputeModalCtrl',
                size: 's',
                resolve: {
                    locationId: function () {
                        return $scope.locationId;
                    }
                }
            });

            modal.result.then(function (hasDispute) {
                $scope.hasDispute = hasDispute;
            });
        };

        /**
         * put a location onto the reminder list
         */
        $scope.remindLocation = function() {
            var list = $cookies.getObject('zwp.remindlist');

            list.push($scope.locationId);

            $cookies.putObject('zwp.remindlist', list, {path: '/'});
        };

        /**
         * removes a location from the reminder list
         */
        $scope.unremindLocation = function() {
            var list = $cookies.getObject('zwp.remindlist');
            var index = list.indexOf($scope.locationId);

            if (index > -1) {
                list.splice(index, 1);
            }

            $cookies.putObject('zwp.remindlist', list, {path: '/'});
        };

        /**
         * check if a location is on the reminder list
         */
        $scope.onRemindList = function() {
            var list = $cookies.getObject('zwp.remindlist');

            // Create list if empty
            if(!(list instanceof Array))
            {
                list = [];
                $cookies.putObject('zwp.remindlist', list, {path: '/'});
            }

            var index = list.indexOf($scope.locationId);

            return (index > -1);
        };
    }]);
