angular.module('zwpSite')
    .controller('ContactModalCtrl', function($scope, $http, $filter, $timeout, $uibModalInstance, locationId) {
        $scope.locationId = locationId;

        $scope.contact = {};
        $scope.contact.salutation = 2;

        $scope.message = "";

        /**
         * submits the rating form
         */
        $scope.submitForm = function() {
            var data = angular.extend({}, $scope.contact);

            if(!jQuery('#g-recaptcha-response').val())
            {
                $scope.showError = true;
                $scope.errorMessage = "Bitte Sicherheitsabfrage bestätigen";
                return false;
            }

            data.captcha = jQuery('#g-recaptcha-response').val();

            $http.post('/locations/' + $scope.locationId + '/contact', data).
                success(function() {
                    $scope.showError = false;
                    $scope.showSuccess = true;
                    $scope.messageClass = "alert alert-success";

                    $timeout(function() {
                        $uibModalInstance.close();
                    }, 5000);
                }).
                error(function(result) {
                    $scope.showError = true;

                    // show error
                    if(result.email)
                    {
                        $scope.errorMessage = "Ungültige E-Mail Adresse";
                    }
                });
        };

        $scope.closeModal = function() {
            $uibModalInstance.close();
        };
    });
