angular.module('zwpSite')
    .controller('SearchCtrl', ['$scope', '$http', '$uibModal', '$filter', '$values', '$attributes', '$location', '$q',
        function($scope, $http, $uibModal, $filter, $values, $attributes, $location, $q) {
            $scope.sliderDefaults = {
                min_price: 10,
                max_price: 10000,
                min_rooms: 1,
                max_rooms: 20,
                min_size: 0,
                max_size: 500
            };

            $scope.slider = {
                priceOptions: {
                    ceil: $scope.sliderDefaults.max_price,
                    floor: $scope.sliderDefaults.min_price,
                    showSelectionBar: true,
                    step: 20,
                    translate: function (value) {
                        return value + '€';
                    }
                },
                roomsOptions: {
                    ceil: $scope.sliderDefaults.max_rooms,
                    floor: $scope.sliderDefaults.min_rooms
                },
                radiusOptions: {
                    ceil: 200,
                    floor: 1,
                    showSelectionBar: true,
                    translate: function (value) {
                        return value + 'km';
                    }
                },
                personOptions: {
                    ceil: 15,
                    floor: 0,
                    showSelectionBar: true
                },
                sizeOptions: {
                    ceil: $scope.sliderDefaults.max_size,
                    floor: $scope.sliderDefaults.min_size,
                    step: 10,
                    translate: function (value) {
                        return value + 'qm';
                    }
                }
            };

            $scope.results = [];
            $scope.meta    = {};
            $scope.now   = new Date();

            $scope.pagination  = {};
            $scope.total       = 0;
            $scope.currentPage = 1;

            $scope.working = false;
            $scope.submitted = false;
            $scope.showAdvanced = false;

            $scope.search = {
                region: '',
                country: 'de',
                size_min: 0,
                size_max: 500,
                rooms_min: 1,
                rooms_max: 20,
                price_min: 0,
                price_max: 10000,
                to: undefined,
                persons: 0,
                attributes: [],
                types: [],
                from: undefined,
                radius: 8,
                openend: false,
                ordering: 'ordered_at.desc'
            };

            $scope.map = null;
            $scope.mapMarkers = [];
            $scope.markerPopups = [];

            $scope.fromPickerOpened = false;
            $scope.toPickerOpened = false;

            $scope.fromPickerOptions = {
                'starting-day':1,
                'minDate' : new Date(),
                'maxMode': 'day'
            };

            $scope.toPickerOptions = {
                'starting-day':1,
                'initDate': new Date(),
                'minDate': new Date(),
                'maxMode': 'day'
            };

            $scope.values = $values;
            $scope.attributes = $attributes;

            $scope.requestCanceller = false;

            /**
             * opens a modal dialog to save the search
             */
            $scope.openSavesearchModal = function() {
                $uibModal.open({
                    templateUrl: '/templates/saveagentform',
                    controller: 'SaveagentModalCtrl',
                    size: 'm',
                    resolve: {
                        search: function () {
                            return $scope.search;
                        }
                    },
                    scope: $scope
                });
            };



            /**
             * open arrival picker
             *
             * @param $event
             */
            $scope.openFromPicker = function($event)
            {
                $event.preventDefault();
                $event.stopPropagation();

                $scope.fromPickerOpened = true;
                $scope.toPickerOpened = false;
            };

            /**
             * open departure picker
             *
             * @param $event
             */
            $scope.openToPicker = function($event)
            {
                $event.preventDefault();
                $event.stopPropagation();

                $scope.toPickerOpened = true;
                $scope.fromPickerOpened = false;
            };

            /**
             * fetch search result
             */
            $scope.fetchResults = function()
            {
                // Don't submit a short search
                if ($scope.search.region.length < 3)
                {
                    return;
                }

                // Convert date objects
                var data = angular.extend({}, $scope.search);
                data.to = $filter('dateToMysql')($scope.search.to);
                data.from = $filter('dateToMysql')($scope.search.from);
                data.page = $scope.currentPage;
                data.openend = data.openend ? 1 : 0;

                // convert attribute and type arrays
                data["attributes[]"] = data.attributes;
                data["types[]"] = data.types;
                delete data.attributes;
                delete data.types;

                // split sorting data
                var orderData = data.ordering.split(".");
                data.ordering = orderData[0];
                data.odir = (orderData[1] == "asc") ? 0 : 1;

                $scope.working = true;
                $scope.submitted = true;

                // Cancel running request
                if($scope.requestCanceller !== false)
                {
                    $scope.requestCanceller.resolve();
                    $scope.requestCanceller = false;
                }

                $scope.requestCanceller = $q.defer();

                // get results
                $http.get('/api/v1/search', {params: data, timeout: $scope.requestCanceller.promise})
                    .success($scope.processResultsResponse)
                    .error(function(data) {
                        $scope.working = false;

                        $scope.results = [];
                        $scope.meta    = {};
                        $scope.pagination  = {};
                    });
            };

            /**
             * process api response
             * @param response
             */
            $scope.processResultsResponse = function(response) {
                // Because of fractal it is always an array
                var result = response[0];

                if (result.hasOwnProperty('data')) {
                    $scope.results = result.data;
                }

                if (result.hasOwnProperty('meta')) {
                    $scope.meta       = result.meta;
                    $scope.pagination = $scope.meta.pagination;
                }

                // convert date objects
                $scope.results.map(function(item) {
                    item.available_from = new Date(item.available_from);
                    return item;
                });

                // update google map
                $scope.updateMap();

                $scope.working = false;
                $scope.requestCanceller = false;

                $scope.saveMetrics();
            };

            /**
             * fetch price result
             */
            $scope.saveMetrics = function()
            {
                if(!$scope.results.length)
                {
                    return;
                }

                var data = {};

                data.locations = $scope.results.map(function(item){
                    return item.id;
                });

                $http.post('/stats/multiadd/1', data)
                    .error(function(data) {
                        console.log('Error' + data);
                    });
            };

            /**
             * fetches search params for location, from, to and guest from $_GET
             */
            $scope.readSearchParamsFromUrl = function () {
                var params = ['region', 'from', 'to', 'country'];
                var triggerSearch = false;

                // Try to fetch search params from url
                angular.forEach(params, function(value) {
                    if($scope.getUrlParameter(value)) {
                        $scope.search[value] = $scope.getUrlParameter(value);

                        if(value == "region" && $scope.search.region == "Berlin")
                        {
                            $scope.search.radius = 18;
                        }

                        triggerSearch = true;
                    }
                });

                if($scope.search.to !== undefined)
                {
                    $scope.search.openend = false;
                }

                if(triggerSearch) {
                    $scope.fetchResults();
                }
            };

            /**
             * fetch regions for autocompletion
             *
             * @param val
             * @returns {*}
             */
            $scope.getRegion = function(val) {
                return $http.get('/api/v1/suggest', {
                    params: {
                        region: val,
                        country: $scope.search.country
                    }
                }).then(function(response){
                    return response.data[0].data.map(function(item){
                        return item.region;
                    });
                });
            };

            /**
             * fetch default radius for region
             *
             * @param val
             * @returns {*}
             */
            $scope.fetchDefaultRadius = function() {
                return $http.get('/api/v1/region/' + $scope.search.region)
                    .then(function(response){
                        var radius = parseInt(response.data[0].data.default_radius);

                        if (radius)
                        {
                            $scope.search.radius = radius;
                        }
                    });
            };

            /**
             * reads query parameters from current url
             *
             * @param name
             * @returns {string}
             */
            $scope.getUrlParameter = function(name) {
                name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
                var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
                    results = regex.exec(location.search);
                return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
            };

            /**
             * initialize google map
             */
            $scope.initializeMap = function() {
                if(typeof google == 'undefined')
                {
                    return;
                }

                var mapOptions = {
                    zoom: 5,
                    center: {lat: 51.163375, lng: 10.447683333333},
                    minZoom: 0,
                    maxZoom: 15,
                    scrollwheel: false
                };

                $scope.map = new google.maps.Map(document.getElementById('gmap'), mapOptions);

                // Fix scroll behavoior
                var gmapContainer = document.getElementById('gmap-container');
                var gmapParent = gmapContainer.parentElement;
                var header = document.getElementById('header');
                var footer = document.getElementById('footer');
                var currentPageY = 0;
                var currentHeaderHeight = header.offsetHeight;
                var currentFooterHeight = footer.offsetHeight;

                // Position fixed ignored content like absolute, so the height-
                // does not expand the div.
                gmapParent.style.paddingBottom = '100px';

                // Apply native settings
                gmapContainer.style.position = 'fixed';
                gmapContainer.style.right = (document.body.offsetWidth - 1200) / 2 + 'px';
                gmapContainer.style.maxWidth = '500px';

                window.addEventListener('resize', function() {
                    gmapContainer.style.right = (document.body.offsetWidth - 1200) / 2 + 'px';

                    if (document.body.offsetWidth < 1200) {
                        gmapContainer.style.right = '0';
                    }

                    if (header.offsetHeight !== currentHeaderHeight) {
                        currentHeaderHeight = header.offsetHeight;
                    }

                    // If pageY is equal to 0 then the user is at the top of the website
                    // Reset position and set it to its default value
                    if (currentPageY === 0) {
                        gmapContainer.style.top = currentHeaderHeight + 'px';
                    }
                });

                window.addEventListener('scroll', function(ev) {
                    currentPageY = ev.pageY;

                    // User scrolled way up to the top
                    if (ev.pageY === 0) {
                        // Set to default position
                        gmapContainer.style.top = currentHeaderHeight + 'px';
                    }

                    // Header is beyond the screen
                    // Set top to 0, so the map is always at the top
                    if (ev.pageY > currentHeaderHeight) {
                        gmapContainer.style.top = '0';
                    }

                    // Dont add the scroll value up, because then, there is a margin between the top and the map
                    if (ev.pageY < currentHeaderHeight) {
                        gmapContainer.style.top = currentHeaderHeight - ev.pageY + 'px';
                    }


                    // Dont run into the footer
                    if (((ev.pageY + window.innerHeight) > (document.body.clientHeight - currentFooterHeight)) && (document.body.clientHeight > currentFooterHeight + currentHeaderHeight + 550)) {
                        gmapContainer.style.top = ((document.body.clientHeight - currentFooterHeight) - (ev.pageY + window.innerHeight)) + 'px';
                    }

                });
            };

            /**
             * update google map
             */
            $scope.updateMap = function() {
                if(typeof google == 'undefined')
                {
                    return;
                }

                // remove existing markers and reset array
                angular.forEach($scope.mapMarkers, function(marker) {
                    marker.setMap(null);
                });

                $scope.mapMarkers = [];
                $scope.markerPopups = [];

                // reset map to initial coordinates on empty resultset
                if($scope.results.length === 0)
                {
                    $scope.map.setCenter({lat: 51.163375, lng: 10.447683333333});
                    $scope.map.setZoom(6);
                    jQuery('#gmap').css({opacity: 0.4});

                    return;
                }
                else
                {
                    jQuery('#gmap').css({opacity: 1});
                }

                var zIndex = 1000;

                // add marker per location
                angular.forEach($scope.results, function(item) {
                    if(item.lat === 0 && item.lon === 0)
                    {
                        return;
                    }

                    zIndex++;

                    var boxcontent = '<div class="objectbox">' +
                        '<a href="/inserate/' + parseInt(item.id) + '/' + item.slug + '" target="_blank"> ' +
                        '<img src="' + item.thumb_image + '" alt="' + item.title + '" class="objectbox__image"/>' +
                        '</a>' +
                        '<div class="objectbox__text">' +
                        '<div class="objectbox__description">' +
                        '<h4 class="objectbox__title">' + $filter('escapeHtml')(item.title) + '</h4>';

                    if(!item.disabled_ratings) {
                        boxcontent = boxcontent + '<span class="ratingbar" ng-hide="item.disabled_ratings">';

                        for(i = 1; i < 6; i++) {
                            boxcontent = boxcontent + '<span class="icon-star ratingbar__star';

                            if (i <= item.rating) {
                                boxcontent = boxcontent + ' ratingbar__star--active';
                            }

                            boxcontent = boxcontent + '"></span>';
                        }

                        boxcontent = boxcontent + '</span>';
                    }

                    boxcontent = boxcontent + '<dl class="objectbox__keyfacts">' +
                        '<dt class="sr-only">Preis</dt>' +
                        '<dd>' + $filter('currency')(item.price, $values.getCurrencySymbol(item.currency)) + '<span class="objectbox__keyfactspricenote">i.d.R. inkl. NK</span></dd>' +
                        '<dt class="sr-only">Größe</dt>' +
                        '<dd>' + $filter('number')(item.size, 0) + ' m<sup>2</sup></dd>' +
                        '<dt class="sr-only">Zimmer</dt>' +
                        '<dd><span class="icon-rooms"></span> ' + parseInt(item.rooms) + '</dd>' +
                        '</dl>' +
                        '<p class="objectbox__address">' +
                        $filter('escapeHtml')(item.zip) + ' ' + $filter('escapeHtml')(item.city) + '<br />' +
                        'Bezugsfrei ab';

                    if(item.available_from <= new Date()) {
                        boxcontent = boxcontent + 'sofort';
                    } else {
                        boxcontent = boxcontent + $filter('date')(item.available_from, 'dd.MM.yyyy');
                    }

                    boxcontent = boxcontent + '</p>' +
                        '<a href="/inserate/' + item.id + '/' + item.slug + '" target="_blank" class="btn btn-default objectbox__stickybutton">Zum Objekt</a>' +
                        '</div>' +
                        '</div>' +
                        '</div>';

                    var infowindow = new google.maps.InfoWindow({
                        content: boxcontent,
                        maxWidth: 240
                    });

                    var marker = new google.maps.Marker({
                        position: {lat: item.lat, lng: item.lon},
                        map: $scope.map,
                        icon: '/images/gmapmarker.png',
                        label: {
                            text: parseInt(item.price) + ' ' + $values.getCurrencySymbol(item.currency),
                            color: 'white',
                            fontSize: '12px',
                            fontFamily: "'Source Sans Pro',sans-serif"
                        },
                        zIndex: zIndex
                    });

                    marker.addListener('click', function() {
                        infowindow.open($scope.map, marker);
                    });


                    $scope.markerPopups.push(
                        infowindow
                    );

                    $scope.mapMarkers.push(
                        marker
                    );
                });

                // set correct bounds
                var bounds = new google.maps.LatLngBounds();

                for (var i = 0; i < $scope.mapMarkers.length; i++) {
                    bounds.extend($scope.mapMarkers[i].getPosition());
                }

                $scope.map.fitBounds(bounds);
            };

            $scope.clickToDateBox = function($event) {
                if($scope.search.openend)
                {
                    $scope.search.openend = false;
                    $scope.toPickerOpened = true;
                    $scope.fromPickerOpened = false;
                }
            };

            $scope.setMinToDate = function()
            {
                var to = new Date();

                if (!($scope.search.from instanceof Date))
                {
                    to.setTime(to.getTime() + 31 * 86400000);
                }
                else
                {
                    to.setTime($scope.search.from.getTime() + 31 * 86400000);
                }

                $scope.toPickerOptions.minDate = to;
                $scope.toPickerOptions.initDate = angular.copy(to);

                if($scope.search.to < $scope.toPickerOptions.minDate)
                {
                    $scope.search.to = $scope.toPickerOptions.minDate;
                }
            };

            $scope.fetchAndResetPage = function() {
                $scope.currentPage = 1;
                $scope.fetchResults();
            };

            $scope.init = function() {
                $scope.readSearchParamsFromUrl();
                $scope.initializeMap();

                /**
                 * autorefresh search when something is changed
                 */
                $scope.$watch('search.region', function() {$scope.currentPage = 1;}, false);
                $scope.$watch('search.region', $scope.fetchDefaultRadius, false);
                $scope.$watch('search.from', $scope.setMinToDate, false);
                $scope.$watch('search.openend', function() {if($scope.search.openend) {$scope.search.to = undefined;}}, false);

                $scope.$watch('search', $scope.fetchAndResetPage, true);

                /**
                 * restore pagination
                 */
                $scope.$watch('currentPage', function() {
                    if ($scope.currentPage !== undefined)
                    {
                        $location.url('/?page=' + $scope.currentPage);

                        // Apply scrolling
                        window.scrollTo(0, 0);
                    }
                });
                $scope.$on('$locationChangeSuccess', function (e, newUrl, oldUrl) {
                    if (newUrl === null || oldUrl === null || newUrl == oldUrl)
                    {
                        return;
                    }

                    var page = $location.search().page;

                    if (page == $scope.currentPage)
                    {
                        return;

                    }
                    else
                    {
                        $scope.currentPage = page;
                        $scope.fetchResults();
                    }
                });
            };


        }]);
