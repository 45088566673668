angular.module('zwpSite')
    .controller('RatingModalCtrl', function($scope, $http, $timeout, $uibModalInstance, locationId, ratingToken) {
        $scope.locationId = locationId;

        $scope.rating = {};
        $scope.rating.rating_fittings = 5;
        $scope.rating.rating_condition = 5;
        $scope.rating.rating_description = 5;
        $scope.rating.rating_reachability = 5;
        $scope.rating.author_salutation = 0;

        $scope.message = "";

        /**
         * submits the rating form
         */
        $scope.submitForm = function() {
            var data = angular.extend({}, $scope.rating, {location: $scope.locationId, token: ratingToken});

            $http.post('/ratings/store', data).
                success(function() {
                    $scope.showSuccess = true;
                    $scope.messageClass = "alert alert-success";

                    $timeout(function() {
                        $uibModalInstance.close();
                    }, 3000);
                }).
                error(function() {
                    $scope.showError = true;
                    $scope.messageClass = "alert alert-error";

                    $timeout(function() {
                        $uibModalInstance.close();
                    }, 3000);
                });
        };

        $scope.closeModal = function() {
            $uibModalInstance.close();
        };
    });
